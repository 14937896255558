const SHOW_PROJECTS = false;

const mapResponsiveValues = (mediaQueryObject, propertyName) =>
  Object.keys(mediaQueryObject)
    .map(
      key =>
        key !== "0"
          ? `@media only screen and (min-width: ${key}px) { 	
						${propertyName}: ${mediaQueryObject[key]};
					}`
          : `${propertyName}: ${mediaQueryObject[key]};`
    )
    .join("\n");

const grid = (name, defaultValue) => props => {
  let value = props[name];
  const valueHasResponsiveStyles = typeof value === "object";
  /**
   * Grid has many grid-*-* properties; underscores should be used in props
   * and string keys to keep this syntax uniform and terse.
   *
   * Underscores should be replaced with dashes when style string literal
   * is returned.
   */
  const computedCSSProperty = `grid-${name.replace(/\_/g, "-")}`;

  if (!value && typeof defaultValue === "undefined") return "";
  if (!value) value = defaultValue;

  if (valueHasResponsiveStyles) {
    return mapResponsiveValues(value, computedCSSProperty);
  } else {
    return `${computedCSSProperty}: ${value};`;
  }
};

const markdownExternalLinks = htmlString =>
  htmlString.replace(
    /href="http/g,
    'rel="noopener noreferrer" title="Link opens in new tab" target="_blank" href="http'
  );

const prefixAttr = ({ object, prefix }) =>
  Object.entries(object)
    .map(([key, val]) => [`${prefix}${key}`, val])
    .reduce((acc, [attr, val]) => {
      acc[attr] = val;
      return acc;
    }, {});

const spectreLinkResets = props => `
	/* resets */
		color: unset;
		text-decoration: none;
		&:hover {
			/* resets */
			color: unset;
			text-decoration: none;
		}
		&:active {
			/* resets */
			color: unset;
			text-decoration: none;
		}
		&:focus {
			/* resets */
			color: unset;
			text-decoration: none;
		}`;

export {
  SHOW_PROJECTS,
  grid,
  markdownExternalLinks,
  prefixAttr,
  spectreLinkResets
};
