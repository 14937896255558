import React from "react"
import Link from "gatsby-link"
import styled from "styled-components"
import { siteMetadata } from "../../../gatsby-config"

const Title = styled("h2")`
  font-size: 42px;
  margin: 0;
`

const TitleLink = styled(Link)`
  color: #fff;
  padding: 0 0.25em;
  text-decoration: none;

  &:hover {
    color: #fff;
    outline-width: 3px;
    text-decoration: none;
  }
  &:focus {
    color: #fff;
    outline: 3px dotted #fff; //#3b99fc;
    text-decoration: none;
  }
`

const StyledHeader = styled("header")`
  background: #ca1211;
  section {
    margin: 0 auto;
    padding: 1.45rem 1.0875rem;
    max-width: 960px;
  }
`

const Header = props => (
  <StyledHeader {...props}>
    <section>
      <Title>
        <TitleLink to="/">{siteMetadata.title}</TitleLink>
      </Title>
    </section>
  </StyledHeader>
)

export default Header
