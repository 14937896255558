const capitalize = ([firstLetter, ...restLetters]) =>
  `${firstLetter.toUpperCase()}${restLetters}`

const capitalizeAll = string =>
  string
    .split(" ")
    .map(subString => capitalize(subString))
    .join(" ")

const ogMeta = ({ name, property, content }) => {
  const supportedOGNames = ["url", "title", "description", "image"]
  const supportedTwitterNames = ["card", "site", "creator", "image"]

  if (property && !supportedOGNames.includes(property)) return null
  if (name && !supportedTwitterNames.includes(name)) {
    return null
  } else if (name === "card") {
    switch (content) {
      case "summary":
      case "summary_large_image": {
        break
      }
      default: {
        throw `TwitterMetadataError: ${content} is not a valid property for the twitter:card field.`
      }
    }
  }

  const { computedKey, value } = name
    ? { computedKey: "name", value: `twitter:${name}` }
    : property
    ? { computedKey: "property", value: `og:${property}` }
    : false

  if (!computedKey) return null

  return {
    [computedKey]: value,
    content,
  }
}

const slugify = string =>
  string
    .replace(/\s/g, "-")
    .replace(/[^a-zA-Z0-8\-]/g, "")
    .toLowerCase()

const unSlugify = string => string.split("-").join(" ")

const stripHTML = string =>
  string.replace(/\<[a-zA-Z0-9\s\=\"\-\/\\\#\(\)\[\]]+\>/g, "")

export { capitalize, capitalizeAll, ogMeta, slugify, stripHTML, unSlugify }
