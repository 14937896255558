import React from "react";
import styled from "styled-components";

const Button = styled("a")`
	background: #fff;
	border: 2px solid currentcolor;
	color: #ca1211;
	font-size: 1.25em;
	padding: 0.5em 0.5em;
	opacity: 0;
	position: fixed;
	top: -100vh;
	left: -100vw;

	&:focus {
		opacity: 1;
		outline: 0.125em dotted #fff;
		top: 6px;
		left: 6px;
	}
`;

const JumpToContentButton = props => (
	<Button href="#content" {...props}>
		jump to content
	</Button>
);

export default JumpToContentButton;
