import React from "react"
import Helmet from "react-helmet"
import { ogMeta } from "../../utilities/functions"
import genericSocialImage from "../../images/social-image-generic.png"

const Metadata = ({ frontmatter }) => {
  const {
    title: metaTitle,
    description: metaDescription,
    keywords: metaKeywords,
    og_url: ogURL,
    og_image: ogImage,
    twitter_card: twitterCard,
    twitter_creator: twitterCreator,
  } = frontmatter

  const { GATSBY_SITE_URL } = process.env

  return (
    <Helmet
      title={metaTitle}
      meta={[
        metaDescription
          ? { name: "description", content: metaDescription }
          : false,
        metaKeywords ? { name: "keywords", content: metaKeywords } : false,
        ogURL
          ? ogMeta({
              property: "url",
              content: `https://${GATSBY_SITE_URL}${ogURL}`,
            })
          : false,
        ogImage
          ? ogMeta({
              name: "image",
              property: "image",
              content:
                ogImage.search(/^http/) >= 0
                  ? ogImage
                  : `https://${GATSBY_SITE_URL}${ogImage}`,
            })
          : {
              name: "twitter:image",
              property: "og:image",
              content: `https://${GATSBY_SITE_URL}${genericSocialImage}`,
            },
        metaTitle
          ? ogMeta({
              property: "title",
              content: metaTitle,
            })
          : false,
        metaDescription
          ? ogMeta({
              property: "description",
              content: metaDescription,
            })
          : false,
        twitterCard
          ? ogMeta({
              name: "card",
              content: twitterCard,
            })
          : false,
        twitterCreator
          ? ogMeta({
              name: "creator",
              content: twitterCreator,
            })
          : false,
      ].filter(object => object)}
    />
  )
}

export default Metadata
