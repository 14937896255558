import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";

const Navigation = styled("nav")`
	background-color: #f0f0f0;
	display: flex;
	font-size: 20px;
	justify-content: center;
	margin: auto auto 2.5em;
	padding: 1em;
`;

const NavLink = styled(Link)`
	margin: auto 0.25em;
	padding: 0.25em 0.5em;
`;

export default ({ navRoutes }) => {
	const NavLinks = navRoutes.map(({ path, label }) => (
		<NavLink key={path} to={path}>
			{label}
		</NavLink>
	));
	return <Navigation id="site">{NavLinks}</Navigation>;
};
