import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import Header from "../components/Header/"
import Navigation from "../components/Navigation/"
import JumpToContent from "../components/shared/JumpToContent"
import Metadata from "../components/shared/Metadata"
import { SHOW_PROJECTS } from "../utilities/helpers"

import "../vendor/spectre.css/dist/spectre.css"
import "../vendor/spectre.css/dist/spectre-exp.css"
import "../vendor/spectre.css/dist/spectre-icons.css"
import "./index.css"

const LayoutMain = styled("main")`
  margin: 0 auto;
  max-width: 1024px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
`

const TemplateWrapper = ({ children, data }) => {
  const {
    allDataJson: { edges: dataEdges },
    allSitePage: { edges: sitePageEdges },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      allDataJson(filter: { title: { eq: "routes" } }) {
        edges {
          node {
            title
            content {
              routes {
                label
                path
                order
              }
            }
          }
        }
      }
      allSitePage(filter: { path: { regex: "/^/([a-z]+-?[0-9]*/)?$/" } }) {
        edges {
          node {
            path
          }
        }
      }
    }
  `)
  const routes = [...dataEdges[0].node.content.routes]
  const navRoutes = sitePageEdges
    .map(({ node: { path } }) => routes.filter(r => r.path === path)[0])
    .filter(r => {
      if (r && r.label === "Projects") {
        return SHOW_PROJECTS ? r : false
      }
      return r
    })
    .sort(({ order: aOrder }, { order: bOrder }) => aOrder - bOrder)

  return (
    <div itemScope itemType="http://schema.org/WebPage">
      <JumpToContent />
      <Metadata
        frontmatter={{
          title: "Home | Geoff Davis",
          description: "I am a web developer located in Memphis, TN.",
          keywords:
            "geoff,davis,geoffrey,web,developer,memphis,tn,tennessee,javascript,gdavis92,geoffdavis92",
        }}
      />
      <Header role="banner" />
      <Navigation {...{ navRoutes }} />
      <LayoutMain role="main" itemProp="mainContentOfPage">
        {children}
      </LayoutMain>
      <footer id="site-footer">
        <p>&copy;{new Date().getFullYear()} Geoff Davis</p>
      </footer>
    </div>
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper

// export const layoutQuery = graphql`
//   query LayoutQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allDataJson(filter: { title: { eq: "routes" } }) {
//       edges {
//         node {
//           title
//           content {
//             routes {
//               label
//               path
//               order
//             }
//           }
//         }
//       }
//     }
//     allSitePage(filter: { path: { regex: "/^/([a-z]+-?[0-9]*/)?$/" } }) {
//       edges {
//         node {
//           path
//         }
//       }
//     }
//   }
// `
